import {getJwtToken} from "../token_service";
import axios from "axios";
import {API_URL} from "../constants";


export async function getProductsList() { 
    let config = {
        method: 'get',
        url: API_URL + '/api/Product',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        }
    };
    
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}