import React from 'react';
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

export const DropdownKlient = ({...args}) => {
    return (
        <div className="dropdown dropdown-bottom">
            <label tabIndex={0} className="btn m-1 mt-0 bg-yellow-600 border-0 rounded-sm">Klient</label>
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-auto whitespace-nowrap">
                <li><a href={"/create-order"} className={"btn-ghost hover:bg-yellow-600"}>Nowa awizacja</a></li>
                <li><a href={"/orders-client"} className={"btn-ghost hover:bg-yellow-600"}>Lista zleceń</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600"}>Cennik</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600"}>Raporty</a></li>
            </ul>
        </div>
    );
}


export const DropdownKlientMobile = ({...args}) => {
    return (
            <ul className="menu">
                <li><a className="btn btn-ghost" href={"/create-order"}>Nowa awizacja</a></li>
                <li><a className="btn btn-ghost" href={"/orders-client"}>Lista zleceń</a></li>
                <li><a className="btn btn-ghost">Cennik</a></li>
                <li><a className="btn btn-ghost">Raporty</a></li>
            </ul>
    );
}

export default DropdownKlient;