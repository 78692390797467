import React, {Component} from 'react';
import {NavMenu} from './NavMenu';
import {Footer} from "./Footer";
import {isJwtTokenRefreshable, refreshJwtToken} from "../app/token_service";

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        if (isJwtTokenRefreshable())
        {
            refreshJwtToken();
        }
        
        return (
            <div className="min-h-full">
                <NavMenu/>
                <div className="mb-16">
                    {this.props.children}
                </div>
                <Footer/>
            </div>
        );
    }
}
