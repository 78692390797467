import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {acceptOrder, getOrdersBySearchCriteria, rejectOrder} from "../../app/requests/order";
import {getProductsList} from "../../app/requests/productsList";
import {getTerminalList} from "../../app/requests/terminalList";
import {getCustomerList} from "../../app/requests/user";
import {isJwtTokenExpired, isTrader, removeJwtToken} from "../../app/token_service";


function createInitialSearchCriteria(searchParams) {
    return {
        OrderNumber: searchParams.get("OrderNumber") || "",
        OrderStatus: Number(searchParams.get("OrderStatus")) ?? -1,
        Client: Number(searchParams.get("Client")) || "",
        TerminalId: Number(searchParams.get("TerminalId")) || -1,
        ProductId: Number(searchParams.get("ProductId")) || -1,
        DateFrom: searchParams.get("DateFrom") || '',
        DateTo: searchParams.get("DateTo") || '',
        WeightFrom: Number(searchParams.get("WeightFrom")) || '',
        WeightTo: Number(searchParams.get("WeightTo")) || '',
    }
}

export default function OrderListTrader() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchCriteria, setSearchCriteria] = useState(createInitialSearchCriteria(searchParams));
    const [orders, setOrders] = useState([]);

    const [products, setProducts] = useState([]);
    const [terminals, setTerminals] = useState([]);
    const [customerList, setCustomerList] = useState([]);

    const [selectedOrders, setSelectedOrders] = useState([]);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(25);

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    function initialFetch() {
        getCustomerList().then(setCustomerList).catch(() => setError("Wystąpił błąd podczas pobierania listy klientów, odśwież stronę aby spróbować ponownie"));
        getProductsList().then(setProducts).catch(() => setError("Wystąpił błąd podczas pobierania listy produktów, odśwież stronę aby spróbować ponownie"));
        getTerminalList().then(setTerminals).catch(() => setError("Wystąpił błąd podczas pobierania listy terminali, odśwież stronę aby spróbować ponownie"));
        if (searchParams.toString() !== "")
            fetchData();
    }

    function fetchData() {
        setSuccess(false);
        setSelectedOrders([]);
        getOrdersBySearchCriteria(JSON.parse(JSON.stringify({...searchCriteria, OrderStatus: searchCriteria.OrderStatus===-1?undefined:searchCriteria.OrderStatus}))).then(r => setOrders(r.data));
    }
    
    function acceptOrders() {
        setSuccess(false);
        Promise.all(selectedOrders.map(order => {
            return new Promise((resolve, reject) => {
                acceptOrder(order).then(resolve).catch(reject);
            })
        })).then(() => {
            setSelectedOrders([]);
            fetchData();
        }).catch(() => setError("Wystąpił błąd podczas akceptacji zamówień, odśwież stronę aby spróbować ponownie"));
    }
    
    useEffect(fetchData, [searchCriteria]);
    
    function rejectOrders() {
        setSuccess(false);
        Promise.all(selectedOrders.map(order => {
            return new Promise((resolve, reject) => {
                rejectOrder(order).then(resolve).catch(reject);
            })
        })).then(() => {
            setSelectedOrders([]);
            fetchData();
        }).catch(() => setError("Wystąpił błąd podczas odrzucenia zamówień, odśwież stronę aby spróbować ponownie"));
    }

    function mapStatus(status) {
        switch (status) {
            case 0:
                return "Nowe"
            case 1:
                return "Zaaakceptowane"
            case 2:
                return "Zrealizowane"
            case 3:
                return "Anulowane"
            case 4:
                return "Odrzucone"
            case 5:
                return "Przedawnione"
            default:
                return "Nieznany"
        }
    }

    useEffect(initialFetch, []);

    if (isJwtTokenExpired() || !isTrader()) {
        removeJwtToken();
        window.location.href = "/";
    }

    return (
        <div className="p-8">
            <h1 className="text-2xl font-bold md-3 max-w-md mb-5">Wyszukaj zamówienia</h1>
            <div className="mt-5 overflow-x-auto">
                <table className="table table-compact w-full rounded-none static">
                    <thead className="rounded-none">
                    <tr key="head" className="rounded-none">
                        <th className="rounded-none w-8"/>
                        <th className="rounded-none w-40">Nr zamówienia</th>
                        <th className="rounded-none w-max min-w-96">Nazwa firmy klienta</th>
                        <th className="rounded-none w-28">Status</th>
                        <th className="rounded-none w-48">Terminal</th>
                        <th className="rounded-none w-20">Data od</th>
                        <th className="rounded-none w-20">Data do</th>
                        <th className="rounded-none w-24">Produkt</th>
                        <th className="rounded-none w-24">Waga [t]</th>
                        <th className="rounded-none w-32">Akcje</th>
                    </tr>
                    </thead>
                    <thead className={"mt-0"}>
                    <tr key="head2" className="rounded-none">
                        <th className="rounded-none w-8"/>
                        <th className="rounded-none w-40">
                            <input type="text" className="border border-gray-400 rounded-none p-1 max-w-md"
                                   value={searchCriteria.OrderNumber}
                                   onKeyDown={event => {
                                       if (event.key === 'Enter') fetchData();
                                   }}
                                   onChange={e => setSearchCriteria({
                                       ...searchCriteria,
                                       OrderNumber: e.target.value
                                   })}/>
                        </th>
                        <th className="rounded-none w-max min-w-96">
                            <input type="text" className="input input-sm border border-gray-400 rounded-none p-1 w-max min-w-96"
                                   value={searchCriteria.Client}
                                   onKeyDown={event => {
                                       if (event.key === 'Enter') fetchData();
                                   }}
                                   onChange={e => setSearchCriteria({...searchCriteria, Client: e.target.value})}
                                   list={'customer-selection'}/>
                            <datalist id={'customer-selection'}>
                                {customerList.map(c => {
                                    return <option key={c.id} value={c.companyName}/>
                                })}
                            </datalist>
                        </th>
                        <th className="rounded-none w-28">
                            <select className="border border-gray-400 rounded-none p-1 max-w-md"
                                    value={searchCriteria.OrderStatus}
                                    onChange={e => setSearchCriteria({
                                        ...searchCriteria,
                                        OrderStatus: Number(e.target.value)
                                    })}>
                                <option value={-1}>Wszystkie</option>
                                <option value={0}>Nowe</option>
                                <option value={1}>Zaakceptowane</option>
                                <option value={2}>Zrealizowane</option>
                                <option value={3}>Anulowane</option>
                                <option value={4}>Odrzucone</option>
                                <option value={5}>Przedawnione</option>
                            </select>
                        </th>
                        <th className="rounded-none w-48">
                            <select className="border border-gray-400 rounded-none p-1 max-w-md"
                                    value={searchCriteria.TerminalId}
                                    onKeyDown={event => {
                                        if (event.key === 'Enter') fetchData();
                                    }}
                                    onChange={e => setSearchCriteria({
                                        ...searchCriteria,
                                        TerminalId: Number(e.target.value)
                                    })}>
                                <option value={-1}>Wszystkie</option>
                                {terminals.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
                            </select>
                        </th>
                        <th className="rounded-none w-20">
                            <input type="date" className="border border-gray-400 rounded-none p-1 max-w-md"
                                   value={searchCriteria.DateFrom === "null" ? "" : searchCriteria.DateFrom}
                                   onKeyDown={event => {
                                       if (event.key === 'Enter') fetchData();
                                   }}
                                   onChange={e => setSearchCriteria({
                                       ...searchCriteria,
                                       DateFrom: e.target.value === undefined ? "" : e.target.value
                                   })}/>
                        </th>
                        <th className="rounded-none w-20">
                            <input type="date" className="border border-gray-400 rounded-none p-1 max-w-md"
                                   value={searchCriteria.DateTo === "null" ? "" : searchCriteria.DateTo}
                                   onKeyDown={event => {
                                       if (event.key === 'Enter') fetchData();
                                   }}
                                   onChange={e => setSearchCriteria({
                                       ...searchCriteria,
                                       DateTo: e.target.value === null ? "" : e.target.value
                                   })}/>
                        </th>
                        <th className="rounded-none w-24">
                            <select className="border border-gray-400 rounded-none p-1 max-w-md"
                                    value={searchCriteria.ProductId}
                                    onKeyDown={event => {
                                        if (event.key === 'Enter') fetchData();
                                    }}
                                    onChange={e => setSearchCriteria({
                                        ...searchCriteria,
                                        ProductId: Number(e.target.value)
                                    })}>
                                <option value={-1}>Wszystkie</option>
                                {products.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
                            </select>
                        </th>
                        <th className="rounded-none w-24">
                        <input type="number" className="border border-gray-400 rounded-none p-1 max-w-[3rem]"
                               value={searchCriteria.WeightFrom}
                               onKeyDown={event => {
                                   if (event.key === 'Enter') fetchData();
                               }}
                               onChange={e => setSearchCriteria({
                                   ...searchCriteria,
                                   WeightFrom: e.target.value
                               })}/>
                            <input type="number" className="border border-gray-400 rounded-none ml-1 p-1 max-w-[3rem]"
                                   value={searchCriteria.WeightTo}
                                   onKeyDown={event => {
                                       if (event.key === 'Enter') fetchData();
                                   }}
                                   onChange={e => setSearchCriteria({
                                       ...searchCriteria,
                                       WeightTo: e.target.value
                                   })}/>
                        </th>
                        
                        <th className="rounded-none w-32"/>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.map(o => <tr key={o.orderNumber}>
                        <td><input type={'checkbox'}
                                   className={"checkbox checkbox-xs rounded-none"}
                                      checked={selectedOrders.includes(o.id)}
                                   onChange={e => {
                                       if (e.target.checked) {
                                           setSelectedOrders([...selectedOrders, o.id]);
                                       } else {
                                           setSelectedOrders(selectedOrders.filter(s => s !== o.id));
                                       }
                                   }}
                        /></td>
                        <td className="w-36">{o.orderNumber}</td>
                        <td className="whitespace-normal">{o.clientName || "Brak firmy"}</td>
                        <td>{mapStatus(o.orderStatus)}</td>
                        <td>{o.terminalName}</td>
                        <td>{o.orderDate.substring(0, 10)}</td>
                        <td>{o.orderEndDate.substring(0, 10)}</td>
                        <td>{o.productName}</td>
                        <td className="text-right">{o.quantity}</td>
                        <td>
                            <a href={`/order-details/${o.id}`}>
                                Szczegóły
                            </a>
                        </td>
                    </tr>)}

                    </tbody>
                </table>
                { orders.length !== 0 ?
                <div className={"place-content-end w-40 right-3"}>
                    <nobr>
                        <button className={"btn btn-sm mt-10"}
                            onClick={() => {
                                acceptOrders();
                            }}
                        >Zaakceptuj zaznaczone</button>
                        <button className={"btn btn-sm mt-10 ml-6"}
                            onClick={() => {
                                rejectOrders();
                            }
                        }>Odrzuć zaznaczone</button>
                    </nobr>
                </div> : null
                }
            </div>
        </div>
    )
}