import { useState } from "react";
import { registration } from "../../app/requests/annonymous";
import { AdminRegistration } from "../../app/requests/admin";
import {
    isAdministrator,
    isJwtTokenExpired
} from "../../app/token_service";

export const RegisterScreen = () => {
    const [UserName, setUsername] = useState('');
    const [Password, setPassword] = useState('');
    const [PasswordConfirm, setPasswordConfirm] = useState('');
    const [Email, setEmail] = useState('');
    const [CompanyName, setCompanyName] = useState('');
    const [CompanyShortName, setCompanyShortName] = useState('');
    const [Country, setCountry] = useState('Polska');
    const [State, setState] = useState('dolnośląskie');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [TaxNumber, setTaxNumber] = useState('');
    const [Address, setAddress] = useState('');
    const [BuildingNumber, setBuildingNumber] = useState('');
    const [City, setCity] = useState('');
    const [PostCode, setPostCode] = useState('');
    const [isStatutAccepted, setisStatutAccepted] = useState(false);
    const [agreementForRecivingOrderStatus, setagreementForRecivingOrderStatus] = useState(false);
    const [exemptionFromExciseDutyDocumentFile, setexemptionFromExciseDutyDocumentFile] = useState();
    const [exemptionFromExciseDutyDocumentName, setexemptionFromExciseDutyDocumentName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [Klient, setKlient] = useState(false);
    const [Trader, setTrader] = useState(false);
    const [Logistyk, setLogistyk] = useState(false);
    const [Terminal, setTerminal] = useState(false);
    const [Administrator, setAdministrator] = useState(false);
    const [success, setSuccess] = useState('');
    //const api = 
    const phoneRegex = new RegExp(/^[0-9]{9}$/);
    const taxNumberRegex = new RegExp(/^[A-Z]{0,2}[0-9]{10}$/);
    const postCodeRegex = new RegExp(/^([0-9]{2}-[0-9]{3})$|^([0-9]{5})$/);
    const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
    // const buildingNumberRegex = new RegExp(/^[0-9]{1,4}[a-zA-Z]{0,1}$/);
    const buildingNumberRegex = new RegExp(/^[\w\\\/]*$/);
    const passwordRegex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{8,}$/)
    const nameRegex = new RegExp(/^[a-zA-Z0-9\p{L}]{2,}$/)
    const [errorDict, setErrorDict] = useState({})

    function togglePassword() {
        const passwordInput = document.getElementById("password");
        if (passwordInput.type === "password") {
            passwordInput.type = "text";
        } else {
            passwordInput.type = "password";
        }
    }
   
    //const api = 
    let input = ''; 
    const onRegister = async () => {
        setError('')
        setLoading(true);
        let flag = false;
        if (!isStatutAccepted) {
            setError("Musisz zaakceptować regulamin");
            input = document.getElementById('statute');
            flag = true;
        }
        if (!postCodeRegex.test(PostCode)) {
            setError("Kod pocztowy musi składać się z 5 cyfr bez myślnika");
            flag = true;
        }
        if (City === '') {
            setError("Miasto nie może być puste");
            flag = true;
        }
        if (!buildingNumberRegex.test(BuildingNumber)) {
            setError("Numer budynku musi składać się z 1-4 cyfr");
            flag = true;
        }
        if (Address === '') {
            setError("Ulica nie może być pusta");
            flag = true;
        }
        if (!taxNumberRegex.test(TaxNumber)) {
            setError("NIP musi składać się z 10 cyfr");
            flag = true;
        }
        if (!phoneRegex.test(PhoneNumber)) {
            setError("Numer telefonu musi składać się z 9 cyfr");
            flag = true;

        }
        if (CompanyName === '') {
            setError("Nazwa firmy nie może być pusta");
            flag = true;
        }
        if (CompanyShortName === '') {
            setError("Skrócona nazwa firmy nie może być pusta");
            flag = true;
        }
        
        if (!emailRegex.test(Email)) {
            setError("Niepoprawny adres email");
            flag = true;
        }
        if (!passwordRegex.test(Password)) {
            setError("Hasło powinno składać sie z wielkich, małych liter, cyfr i znaków specjalnych. Minimalna długość hasła to 8 znaków");
            flag = true;

        }
        if (Password !== PasswordConfirm) {
            setError("Hasła muszą być zgodnie");
            flag = true;
        }
        if (Password === '') {
            setError("Hasło nie może być puste");
            flag = true;
        }
        if (UserName === '') {
            setError("Nazwa użytkownika nie może być pusta");
            flag = true;

        }
        if (!nameRegex.test(UserName)) {
            setError("Nazwa użytkownika zawiera niedozwolone znaki");
            flag = true;
            
        }
        if (flag) {
            setLoading(false);

            return
        }
            if (!flag) {
            try {
                await registration(UserName, Password, PasswordConfirm,
                    Email, CompanyName, CompanyShortName,
                    Country, State, PhoneNumber, TaxNumber,
                    Address, BuildingNumber, City, PostCode,
                    isStatutAccepted, agreementForRecivingOrderStatus,
                    exemptionFromExciseDutyDocumentName,
                    exemptionFromExciseDutyDocumentFile)
                    .then(response => {
                        console.log(response);
                        if (response.status >= 200 && response.status < 300) {
                            setSuccess('Sprawdź skrzynkę e-mail w celu weryfikacji konta');
                            //window.location.reload();
                        } else {
                            setError(response.data.message)
                            
                        }
                    });
                //window.location.reload();
                //window.location.href = window.location.href;
            } catch (e) {
               
                console.log(e)
                
            }
        }



        setLoading(false);
    };

    const onAdminRegister = async () => {
        setError('')
        setLoading(true);
        let flag = false;
        if (!Klient && !Trader && !Logistyk && !Terminal && !Administrator) {
            setError("Musisz wybrać przynajmniej jedną rolę");
            flag = true;
        }
        if (!phoneRegex.test(PhoneNumber)) {
            setError("Numer telefonu musi składać się z 9 cyfr");
            flag = true;

        }
        if (!taxNumberRegex.test(TaxNumber)) {
            setError("NIP musi składać się z 10 cyfr");
            flag = true;
        }
        if (!postCodeRegex.test(PostCode)) {
            setError("Kod pocztowy musi składać się z 5 cyfr bez myślnika");
            flag = true;
        }
        if (!emailRegex.test(Email)) {
            setError("Niepoprawny adres email");
            flag = true;
        }
        if (!buildingNumberRegex.test(BuildingNumber)) {
            setError("Numer budynku musi składać się z 1-4 cyfr");
            flag = true;
        }
        if (Password !== PasswordConfirm) {
            setError("Hasła muszą być zgodnie");
            flag = true;
        }
        
        if (UserName === '') {
            setError("Nazwa użytkownika nie może być pusta");
            flag = true;

        }
        if (!nameRegex.test(UserName)) {
            setError("Nazwa użytkownika zawiera niedozwolone znaki");
            flag = true;

        }
        if (flag) {
            setLoading(false);

            return
        }
        if (!flag) {
            try {
                await AdminRegistration(UserName, Password, PasswordConfirm,
                    Email, CompanyName, CompanyShortName,
                    Country, State,
                    PhoneNumber, TaxNumber,
                    Address, BuildingNumber, City, PostCode,
                    Klient, Trader, Logistyk, Terminal, Administrator,
                    exemptionFromExciseDutyDocumentName,
                    exemptionFromExciseDutyDocumentFile)
                    .then(response => {
                        console.log(response);
                        if (response.status >= 200 && response.status < 300) {
                            setSuccess('Użytkownik utworzony');
                           
                        } else {
                            setError(response.data.message)
                            
                            
                        }
                    });

            } catch (e) {
                console.log(e)
                setError(e)
            }
        }
        setLoading(false);
    };

    const saveFile = (e) => {
        setexemptionFromExciseDutyDocumentFile(e.target.files[0]);
        setexemptionFromExciseDutyDocumentName(e.target.files[0].name);
    }
    return (
        
        <div className="p-8 pt-0 my-8 mx-auto rounded-sm max-w-[96rem]">
            <div className="register-screen__form">
                <div className="register-screen__form__header">
                    <center><h1 className="text-4xl font-bold md-3 max-w-md">Rejestracja</h1></center>
                </div>
                
                {error && <p className="error">{error}</p>}
                <div className="register-screen__form__body">
                    <form id="registration-form">
                        <div className="overflow-x-auto">
                            <table class="table-auto" className="table table-compact w-full static">

                                <tbody><tr>
                                    <td ><label className="text-sm font-bold mt-1 text-lg">Nazwa użytkownika</label></td>
                                    <td ><input title="małe i wielkie znaki, cyfry (bez polskich znaków"
                                        id="Username"
                                        type="text"
                                        title="Tylko wielkie i małe litery, cyfry, bez polskich znaków"
                                        placeholder="Nazwa użytkownika"
                                        className={"input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md" + (!errorDict['username'] ? ' border-red-500' : '')}
                                        value={UserName}
                                        onChange={e => {
                                            setUsername(e.target.value);
                                            if (nameRegex.test(e.target.value)) { setErrorDict({ ...errorDict, username: true }); }
                                            else { setErrorDict({ ...errorDict, username: false }); }
                                        }}
                                        disabled={success !== ''} 
                                    /></td></tr>
                                    <tr><td><label className="text-sm font-bold mt-1 text-lg">Hasło</label></td>
                                        <td>
                                            <input
                                                id="password"
                                                type="password"
                                                title="min. 8 znaków, małe, wielkie litery, cyfry, znaki specjalne"
                                                placeholder="hasło"
                                                className={"input input-bordered input-sm bordered-base-100 ml-3 rounded-none  w-full max-w-md" + (!errorDict['password'] ? ' border-red-500' : '')}
                                            value={Password}
                                                onChange={e => {
                                                    setPassword(e.target.value);
                                                    if (passwordRegex.test(e.target.value)) { setErrorDict({ ...errorDict, password: true }); }
                                                    else { setErrorDict({ ...errorDict, password: false }); }
                                                }
                                                }
                                            disabled={success !== ''}
                                            /><br/><input
                                            type="checkbox"
                                                className="checkbox checkbox-sm ml-3 mt-1 rounded-none text-lg"
                                                onChange={togglePassword}
                                                disabled={success !== ''} /><label>Podgląd hasła</label>
                                        </td></tr>
                                    <tr><td><label className="text-sm font-bold mt-1 text-lg">Powtórz hasło</label></td>
                                        <td><input
                                            id="passwordConfirm"
                                            type="password"
                                            placeholder="powtórz hasło"
                                            className={"input input-bordered input-sm bordered-base-100 ml-3 rounded-none  w-full max-w-md " + (!errorDict['passwordConfirm'] ? ' border-red-500' : '')}
                                            value={PasswordConfirm}
                                            onChange={e => {
                                                setPasswordConfirm(e.target.value);
                                                if (passwordRegex.test(e.target.value)) { setErrorDict({ ...errorDict, passwordConfirm: true }); }
                                                else { setErrorDict({ ...errorDict, passwordConfirm: false }); }
                                            }}
                                            disabled={success !== ''}
                                    /></td></tr>
                                    <tr><td><label className="text-sm font-bold mt-1 text-lg">Adres E-mail</label></td>
                                <td><input
                                            type="text"
                                            id="email"
                                            placeholder="adres email"
                                            className={"input input-bordered input-sm bordered-base-100 ml-3 rounded-none  w-full max-w-md" + (!errorDict['email'] ? ' border-red-500' : '')}
                                            value={Email}
                                            onChange={e => { setEmail(e.target.value)
                                                if (emailRegex.test(e.target.value)) { setErrorDict({ ...errorDict, email: true }); }
                                                else { setErrorDict({ ...errorDict, email: false }); }
                                            }}
                                    /></td></tr>
                                    <tr><td><label className="text-sm font-bold mt-1 text-lg">Pełna nazwa firmy</label></td>
                                <td><input
                                            type="text"
                                            id="companyName"
                                            placeholder="Nazwa firmy"
                                            className={"input input-bordered input-sm bordered-base-100 ml-3 rounded-none  w-full max-w-md" + (!errorDict['CompanyName'] ? ' border-red-500' : '')}
                                            value={CompanyName}
                                            onChange={e => {
                                                setCompanyName(e.target.value)
                                                if (e.target.value !== '') {setErrorDict({ ...errorDict, CompanyName: true }); }
                                                else { setErrorDict({ ...errorDict, CompanyName: false }); }
                                            }}
                                            disabled={success !== ''}
                                    /></td></tr>
                                    <tr><td><label className="text-sm font-bold mt-1 text-lg">Skrócona nazwa firma</label></td>
                                <td><input
                                            type="text"
                                            placeholder="Skrócona nazwa firma"
                                            id="companyShortName"
                                            className={"input input-bordered input-sm bordered-base-100 ml-3 rounded-none  w-full max-w-md" + (!errorDict['companyShortName'] ? ' border-red-500' : '')}
                                            value={CompanyShortName}
                                            onChange={e => {
                                                setCompanyShortName(e.target.value)
                                                if (e.target.value !== '') { setErrorDict({ ...errorDict, companyShortName: true }); }
                                                else { setErrorDict({ ...errorDict, companyShortName: false }); }
                                            }}
                                            disabled={success !== ''}
                                    /></td></tr>
                                    <tr><td><label className="text-sm font-bold mt-1 text-lg">Państwo</label></td>
                                {/*<td><input*/}
                                {/*    type="text"*/}
                                {/*    placeholder="Państwo"*/}
                                {/*        className="input input-bordered w-full max-w-full max-h-9"*/}
                                {/*    value={Country}*/}
                                {/*    onChange={e => setCountry(e.target.value}*/}
                                        {/*    /></td></tr>*/}
                                        <td><select className="select  bordered-base-100 ml-3 rounded-none  w-full max-w-md" value={Country} onChange={e => setCountry(e.target.value)} disabled={success !== ''} >
                                            <option >Polska</option>
                                            <option>Cypr</option>
                                            <option>Czechy</option>
                                            <option>Niemcy</option>
                                            <option>Słowacja</option>
                                        </select></td></tr>
                                    <tr><td><label className="text-sm font-bold mt-1 text-lg">Województwo</label></td>
                                {/*<td><input*/}
                                {/*    type="text"*/}
                                {/*    placeholder="Region"*/}
                                {/*        className="input input-bordered w-full max-w-full max-h-9"*/}
                                {/*    value={State}*/}
                                {/*    onChange={e => setState(e.target.value}*/}
                                        {
                                            Country === 'Polska' ?
                                                <td><select className="select  bordered-base-100 ml-3 rounded-none  w-full max-w-md" value={State} onChange={e => setState(e.target.value)} disabled={success !== ''} >
                                                    <option >dolnośląskie</option>
                                                    <option>kujawsko-pomorskie</option>
                                                    <option>lubelskie</option>
                                                    <option>lubuskie</option>
                                                    <option>łódzkie</option>
                                                    <option>małopolskie</option>
                                                    <option>mazowieckie</option>
                                                    <option>opolskie</option>
                                                    <option>podkarpackie</option>
                                                    <option>podlaskie</option>
                                                    <option>pomorskie</option>
                                                    <option>śląskie</option>
                                                    <option>świętokrzyskie</option>
                                                    <option>warmińsko-mazurskie</option>
                                                    <option>wielkopolskie</option>
                                                    <option>zachodniopomorskie</option>
                                                </select></td>
                                                :
                                                <td><select className="select  bordered-base-100 ml-3 rounded-none  w-full max-w-md" value={State} onChange={e => setState(e.target.value)} >
                                                    <option>Zagranica</option>
                                                </select></td>
                                        } </tr>
                                    <tr><td><label className="text-sm font-bold mt-1 text-lg">Numer telefonu(9 cyfr)</label></td>
                                <td><input
                                            type="text"
                                            id="phoneNumber"
                                            placeholder="Numer telefonu"
                                            className={"input input-bordered input-sm bordered-base-100 ml-3 rounded-none  w-full max-w-md" + (!errorDict['phoneNumber'] ? ' border-red-500' : '')}
                                            value={PhoneNumber}
                                            onChange={e => {
                                                setPhoneNumber(e.target.value);
                                                if (phoneRegex.test(e.target.value)) { setErrorDict({ ...errorDict, phoneNumber: true }); }
                                                else { setErrorDict({ ...errorDict, phoneNumber: false }); }
                                            }}
                                            disabled={success !== ''}
                                    /></td></tr>
                                    <tr><td><label className="text-sm font-bold mt-1 text-lg">Numer NIP (10 cyfr bez myślników i prefiksu)</label></td>
                                <td><input
                                            type="text"
                                            id="NIP"
                                            placeholder="NIP"
                                            className={"input input-bordered input-sm bordered-base-100 ml-3 rounded-none  w-full max-w-md" + (!errorDict['NIP'] ? ' border-red-500' : '')}
                                    value={TaxNumber}
                                            onChange={e => {
                                                setTaxNumber(e.target.value);
                                                if (taxNumberRegex.test(e.target.value)) { setErrorDict({ ...errorDict, NIP: true }); }
                                                else { setErrorDict({ ...errorDict, NIP: false }); }
                                            }}
                                            disabled={success !== ''}
                                    /></td></tr>
                                    <tr><td><label className="text-sm font-bold mt-1 text-lg">Ulica</label></td>
                                <td><input
                                            type="text"
                                            id="Address"
                                            placeholder="Adres"
                                            className={"input input-bordered input-sm bordered-base-100 ml-3 rounded-none  w-full max-w-md" + (!errorDict['Address'] ? ' border-red-500' : '')}
                                    value={Address}
                                            onChange={e => {
                                                setAddress(e.target.value)
                                                if (e.target.value !== '' || e.target.value !== ' ') { setErrorDict({ ...errorDict, Address: true }); }
                                                else { setErrorDict({ ...errorDict, Address: false }); }
                                            }
                                            }
                                            disabled={success !== ''}
                                    /></td></tr>
                                    <tr><td><label className="text-sm font-bold mt-1 text-lg">Numer budynku</label></td>
                                <td><input
                                            type="text"
                                            id="BulidingNumber"
                                            placeholder="Numer budynku"
                                            className={"input input-bordered input-sm bordered-base-100 ml-3 rounded-none  w-full max-w-md" + (!errorDict['BulidingNumber'] ? ' border-red-500' : '')}
                                            value={BuildingNumber}
                                            onChange={e => {
                                                setBuildingNumber(e.target.value)
                                                if (buildingNumberRegex.test(e.target.value)) { setErrorDict({ ...errorDict, BulidingNumber: true }); }
                                                else { setErrorDict({ ...errorDict, BulidingNumber: false }); }
                                            }
                                            }
                                                disabled = { success !== '' }
                                    /></td></tr>
                                    <tr><td><label className="text-sm font-bold mt-1 text-lg">Miasto</label></td>
                                <td><input
                                            type="text"
                                            id="City"
                                            placeholder="Miasto"
                                            className={"input input-bordered input-sm bordered-base-100 ml-3 rounded-none  w-full max-w-md" + (!errorDict['City'] ? ' border-red-500' : '')}
                                    value={City}
                                            onChange={e => {
                                                setCity(e.target.value);
                                                if (e.target.value!=='') { setErrorDict({ ...errorDict, City: true }); }
                                                else { setErrorDict({ ...errorDict, password: false }); }
                                            }
                                            }
                                            disabled={success !== ''}
                                    /></td></tr>
                                    <tr><td><label className="text-sm font-bold mt-1 text-lg">Kod pocztowy</label></td>
                                <td><input
                                            type="text"
                                            id="PostCode"
                                            placeholder="Kod pocztowy"
                                            className={"input input-bordered input-sm bordered-base-100 ml-3 rounded-none  w-full max-w-md" + (!errorDict['PostCode'] ? ' border-red-500' : '')}
                                    value={PostCode}
                                            onChange={e => {
                                                setPostCode(e.target.value);
                                                if (postCodeRegex.test(e.target.value)) { setErrorDict({ ...errorDict, PostCode: true }); }
                                                else { setErrorDict({ ...errorDict, PostCode: false }); }
                                            }
                                            }
                                            disabled={success !== ''}
                                    /></td></tr>
                                {
                                    isJwtTokenExpired() ?
                                            
                                            <tr><td><label className="text-sm font-bold mt-1 text-lg"><a
                                            onClick={() => {
                                                window.open('/statute')
                                                }} className="link"> Akceptacja regulaminu &emsp; </a></label></td>
                                            <td><input
                                                    type="checkbox"
                                                    id="statute"
                                                value={isStatutAccepted}
                                                    className="checkbox rounded-none"
                                                onChange={e => setisStatutAccepted(e.target.checked)}
                                                    required
                                                    disabled={success !== ''}
                                                /><div className="invalid-feedback">Pole wymagane</div></td></tr>
                                                
                                        
                                        :
                                        null
                                }

                                {
                                    isJwtTokenExpired() ?
                                        
                                            <tr><td><label className="text-sm font-bold mt-1 text-lg">Zgoda na otrzymywanie emaili o statusie zamówienia &emsp;</label></td>
                                            <td><input
                                                type="checkbox"
                                                    className="checkbox rounded-none"
                                                value={agreementForRecivingOrderStatus}
                                                    onChange={e => setagreementForRecivingOrderStatus(e.target.checked)}
                                                    disabled={success !== ''}
                                            /></td>
                                        </tr>
                                        :
                                        null
                                }
                                
                                 

                                            <tr><td><label className="text-sm font-bold mt-1 text-lg">Załącz dokument zwolnienia z akcyzy&emsp;</label></td>
                                            <td><input
                                                type="file"
                                                    className="file-input file-input-bordered file-input-sm w-full max-w-xs rounded-none"
                                                    onChange={saveFile}
                                                    disabled={success !== ''}
                                            /></td>
                                        </tr>
                                        
                                
                                {
                                    !(isJwtTokenExpired()) && isAdministrator() ?
                                        <tr>
                                            <td><input
                                                type="checkbox"
                                                    className="checkbox rounded-none"
                                                value={Klient}
                                                    onChange={e => setKlient(e.target.checked)}
                                                    disabled={success !== ''}
                                            /></td>
                                                <td><label className="text-sm font-bold mt-1 text-lg">&emsp; Klient</label></td>
                                        </tr>
                                        
                                        :
                                        null
                                }
                                {
                                    !(isJwtTokenExpired()) && isAdministrator() ?
                                        <tr>
                                            <td><input
                                                type="checkbox"
                                                    className="checkbox rounded-none"
                                                value={Trader}
                                                    onChange={e => setTrader(e.target.checked)}
                                                    disabled={success !== ''}
                                            /></td>
                                                <td><label className="text-sm font-bold mt-1 text-lg">&emsp; Trader</label></td>
                                        </tr>
                                        
                                        :
                                        null
                                }
                                {
                                    !(isJwtTokenExpired()) && isAdministrator() ?
                                        <tr>
                                            <td><input
                                                type="checkbox"
                                                    className="checkbox rounded-none"
                                                value={Logistyk}
                                                    onChange={e => setLogistyk(e.target.checked)}
                                                    disabled={success !== ''}
                                            /></td>
                                                <td><label className="text-sm font-bold mt-1 text-lg">&emsp; Logistyk</label></td>
                                        </tr>
                                        
                                        :
                                        null
                                }
                                {
                                    !(isJwtTokenExpired()) && isAdministrator() ?
                                        <tr>
                                            <td><input
                                                type="checkbox"
                                                    className="checkbox rounded-none"
                                                value={Terminal}
                                                    onChange={e => setTerminal(e.target.checked)}
                                                    disabled={success !== ''}
                                            /></td>
                                                <td><label className="text-sm font-bold mt-1 text-lg">&emsp; Terminal</label></td>
                                        </tr>
                                        :
                                        null
                                }
                                {
                                    !(isJwtTokenExpired()) && isAdministrator() ?
                                        <tr>
                                            <td><input
                                                type="checkbox"
                                                    className="checkbox rounded-none"
                                                value={Administrator}
                                                    onChange={e => setAdministrator(e.target.checked)}
                                                    disabled={success !== ''}
                                            /></td>
                                                <td><label className="text-sm font-bold mt-1 text-lg">&emsp; Administrator</label></td>
                                        </tr>
                                        
                                        :
                                        null
                                }
                                </tbody>
                            </table>
                        </div>

                                {
                                    !isJwtTokenExpired() && isAdministrator() ?
                                <div className="flex "><button variant="primary" className="btn btn-md mt-2 right-3 bg-yellow-600 border-none rounded-sm disabled:bg-yellow-600 disabled:text-gray-200" type="button" disabled={loading} onClick={onAdminRegister}>


                                            {loading ? 'Ładowanie...' : 'Rejestracja'}
                                        </button></div>
                                        :
                                <div className="flex "><button variant="primary" className="btn btn-md mt-2 right-3 bg-yellow-600 border-none rounded-sm disabled:bg-yellow-600 disabled:text-gray-200" type="button" disabled={loading} onClick={onRegister}>


                                            {loading ? 'Ładowanie...' : 'Rejestracja'}
                                        </button></div>
                                }

                                {error !== "" ?
                                    <div className="alert alert-error shadow-lg">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <span>{error}</span>
                                        </div>
                                    </div> : <div />
                        }
                        {success !== "" ?
                            <div className="alert alert-success shadow-lg">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span>{success}</span>
                                </div>
                            </div> : <div />
                        }
                            
                    </form>
                </div>
            </div>
        </div>
    );
};