import axios from "axios";
import {API_URL} from "../constants";
import { getJwtToken } from "../token_service";

export async function updateUser(username, password, PasswordConfirm,
    Email, CompanyName, CompanyShortName,
    Country, State, PhoneNumber, TaxNumber,
    Address, BuildingNumber, City, PostCode
) {

    let data = JSON.stringify({
        "UserName": username,
        "Password": password,
        "PasswordConfirm": PasswordConfirm,
        "Email": Email,
        "CompanyName": CompanyName,
        "CompanyShortName": CompanyShortName,
        "Country": Country,
        "State": State,
        "PhoneNumber": PhoneNumber,
        "TaxNumber": TaxNumber,
        "Address": Address,
        "BuildingNumber": BuildingNumber,
        "City": City,
        "PostCode": PostCode,

    });
    let config = {
        method: 'put',
        url: API_URL + '/api/User/userUpdate',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        },
        data: data
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}
export async function getUserData(id)
{
    let config = {
        method: 'get',
        url: API_URL + '/api/User/userData?id='+id ,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        }
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

