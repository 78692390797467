import {getJwtToken} from "../token_service";
import axios from "axios";
import {API_URL} from "../constants";


export async function getCustomerList() {
    let config = {
        method: 'get',
        url: API_URL + '/api/user/clients',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        }
    };
    
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}


export function getUsersBySearchCriteria(searchCriteria) {
    if (searchCriteria.DateFrom === "") {
        searchCriteria.DateFrom = "2020-01-01";
    }
    if (searchCriteria.DateTo === "") {
        searchCriteria.DateTo = "2100-01-01";
    }

    let data = JSON.stringify(searchCriteria);
    let config = {
        method: 'post',
        url: API_URL + '/api/user/search',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        },
        data: data
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}


export function getNewClients() {
   

    let config = {
        method: 'GET',
        url: API_URL + '/api/user/getNewClients',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        }
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function acceptUser(id) {

    let config = {
        method: 'POST',
        url: API_URL + '/api/user/acceptClient/' + id ,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        },
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function blockUser(id) {


    let config = {
        method: 'POST',
        url: API_URL + '/api/user/blockAccount/'+ id ,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        },
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

