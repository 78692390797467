import {getJwtTokenCompany, getJwtTokenId} from "../../app/token_service";
import {useEffect, useReducer, useState} from "react";
import {getProductsList} from "../../app/requests/productsList";
import {getTerminalList} from "../../app/requests/terminalList";
import {createOrder, getOrderPrice} from "../../app/requests/order";

function createInitialOrder() {
    return {
        OrderType: 0,
        OrderNumber: "",
        ClientId: getJwtTokenId(),
        TraderId: -1,
        TerminalId: 0,
        OrderDate: '',
        DaysToPick: 0,
        PaymentDays: 0,
        ProductId: 0,
        PriceNetPerTon: 0,
        QuantityTons: 0,
        QuantityCars: 0,
        TransportSbk: false,
        DriversDto: Array(1).fill(defaultDriver()),
        Comment: "",
        Files: []
    }
}

function defaultDriver() {
    return {
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        CarPlate: "",
        DocumentNumber: "",
    }
}

function reducer(state, action) {
    switch (action.type) {
        case "setOrderType":
            return {...state, OrderType: action.payload};
        case "setOrderNumber":
            return {...state, OrderNumber: action.payload};
        case "setTraderId":
            return {...state, TraderId: action.payload};
        case "setTerminalId":
            return {...state, TerminalId: action.payload};
        case "setOrderDate":
            return {...state, OrderDate: action.payload};
        case "setDaysToPick":
            return {...state, DaysToPick: action.payload};
        case "setPaymentDays":
            return {...state, PaymentDays: action.payload};
        case "setProductId":
            return {...state, ProductId: action.payload};
        case "setPriceNetPerTon":
            return {...state, PriceNetPerTon: action.payload};
        case "setQuantityTons":
            if (action.payload === '') {
                return {...state, QuantityTons: undefined, Drivers: []};
            }
            if (action.payload < 0) {
                action.payload = 0;
            }
            if (action.payload > 250) {
                action.payload = 250;
            }
            action.payload = Math.round(action.payload);

            return {...state, QuantityTons: action.payload};
        case "setQuantityCars":
            if (action.payload === '') {
                return {...state, QuantityCars: undefined, Drivers: []};
            }
            if (action.payload < 0) {
                action.payload = 0;
            }
            if (action.payload > 10) {
                action.payload = 10;
            }
            action.payload = Math.round(action.payload);

            const drivers = []
            for (let i = 0; i < action.payload; i++) {
                if (!state.Drivers === undefined && state.Drivers.length > i) {
                    drivers.push(state.Drivers[i]);
                } else {
                    drivers.push(defaultDriver());
                }
            }
            
            return {...state, QuantityCars: action.payload, Drivers: drivers};
        case "setTransportSbk":
            return {...state, TransportSbk: action.payload};
        case "setComment":
            return {...state, Comment: action.payload};
        case "updateDriver":
            const updatedDrivers = [...state.Drivers];
            updatedDrivers[action.payload.index].FirstName = action.payload.firstName !== undefined ? action.payload.firstName.trim() : updatedDrivers[action.payload.index].FirstName;
            updatedDrivers[action.payload.index].LastName = action.payload.lastName !== undefined ? action.payload.lastName.trim() : updatedDrivers[action.payload.index].LastName;
            updatedDrivers[action.payload.index].PhoneNumber = action.payload.phone !== undefined ? action.payload.phone.trim() : updatedDrivers[action.payload.index].PhoneNumber;
            updatedDrivers[action.payload.index].CarPlate = action.payload.car !== undefined ? action.payload.car.trim() : updatedDrivers[action.payload.index].CarPlate;
            updatedDrivers[action.payload.index].DocumentNumber = action.payload.document !== undefined ? action.payload.document.trim() : updatedDrivers[action.payload.index].DocumentNumber;
            return {...state, DriversDto: updatedDrivers};
        case "addFile":
            return {...state, Files: [...state.Files, action.payload]};
        case "removeFile":
            return {...state, Files: state.Files.filter((file, index) => index !== action.payload)};
        case "reset":
            return createInitialOrder();
        default:
            return state;
    }
}

export default function CreateOrderCustomer() {
    const [state, dispatch] = useReducer(reducer, createInitialOrder());

    const [productList, setProductList] = useState([]);
    const [terminalList, setTerminalList] = useState([]);

    const [priceNetto, setPriceNetto] = useState(0);
    const [priceLoading, setPriceLoading] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const [errorDict, setErrorDict] = useState({});
    
    const [file, setFile] = useState(null);

    let handleSubmit = () => {
        setError("");
        setSuccess(false);
        let flag = false;
        let errorDictionary = {};
        if (state.OrderDate === '') {
            setError("Nie podano daty zamówienia lub wprowadzona data nie istnieje w kalendarzu");
            errorDictionary = {...errorDictionary, date: true};
            flag=true;
        }
        else if (state.OrderDate < new Date()) {
            setError("Data zamówienia nie może być wcześniejsza niż dzisiejsza");
            errorDictionary = {...errorDictionary, date: true};
            flag=true;
        }
        if (state.ProductId === 0) {
            setError("Nie wybrano produktu");
            errorDictionary = {...errorDictionary, product: true};
            flag=true;
        }
        if (state.TerminalId === 0) {
            setError("Nie wybrano terminalu");
            errorDictionary = {...errorDictionary, terminal: true};
            flag=true;
        }
        if (state.QuantityTons === 0 || state.QuantityTons === undefined) {
            setError("Nie podano ilości ton");
            errorDictionary = {...errorDictionary, quantity: true};
            flag=true;
        }
        if (state.QuantityCars === 0 || state.QuantityCars === undefined) {
            setError("Nie podano ilości samochodów");
            errorDictionary = {...errorDictionary, quantity: true};
            flag=true;
        }
        if (state.PriceNetPerTon === 0) {
            setError("Ten terminal nie obsługuje tego produktu");
            errorDictionary = {...errorDictionary, price: true};
            flag=true;
        }

        setErrorDict(errorDictionary);

        if (flag) {
            return;
        }

        if (!state.TransportSbk) {
            for (let i = 0; i < state.Drivers.length; i++) {
                if (state.Drivers[i].FirstName === "" || state.Drivers[i].LastName === "" ||state.Drivers[i].CarPlate === "") {
                    setError("Nie podano wszystkich danych kierowcy " + (i + 1));
                    return;
                }
                if (state.Drivers[i].PhoneNumber !== "" && !/^[0-9]{8,10}$/.test(state.Drivers[i].PhoneNumber)) {
                    setError("Niepoprawny numer telefonu kierowcy " + (i + 1));
                    return;
                }

                if (!/^[a-zA-Z0-9]+$/.test(state.Drivers[i].CarPlate)) {
                    setError("Numer rejestracyjny kierowcy " + (i + 1) + " zawiera niedozwolone znaki");
                    return;
                }

                if (state.Drivers[i].DocumentNumber !== "" && !/^[a-zA-Z0-9]+$/.test(state.Drivers[i].DocumentNumber)) {
                    setError("Dokument tożsamości kierowcy " + (i + 1) + " zawiera niedozwolone znaki");
                    return;
                }

                if (!/^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/.test(state.Drivers[i].LastName)) {
                    setError("Nazwisko kierowcy " + (i + 1) + " zawiera niedozwolone znaki");
                    return;
                }

                if (!/^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/.test(state.Drivers[i].FirstName)) {
                    setError("Imię kierowcy " + (i + 1) + " zawiera niedozwolone znaki");
                    return;
                }
            }
        }
        setLoading(true);

        createOrder(state, file)
            .then(() => {
                setLoading(false);
                setSuccess(true);
            })
            .catch(e => {
                setError(e.response.data);
                setLoading(false)
            });

    }

    let updateSummary = async () => {
        await getOrderPrice({ProductId: state.ProductId, ClientId: getJwtTokenId(), TerminalId: state.TerminalId})
            .then(r => {
                dispatch({type: "setPriceNetPerTon", payload: r.data});
                setPriceNetto(r.data);
            })
    }

    useEffect(() => {
        if (state.ProductId === 0 || state.TerminalId === 0) {
            setPriceNetto(0)
            return
        }
        updateSummary().then(r => {
        });

    }, [state.ProductId, state.TerminalId])

    const saveFile = (e) => {
        setFile(e.target.files[0]);
    }
    
    useEffect(() => {
        getProductsList().then(setProductList).catch(() => setError("Wystąpił błąd podczas pobierania listy produktów, odśwież stronę aby spróbować ponownie"));
        getTerminalList().then(setTerminalList).catch(() => setError("Wystąpił błąd podczas pobierania listy terminali, odśwież stronę aby spróbować ponownie"));
    }, [])

    return (
        <div className="p-2">
            <div
                className="p-8 pt-0 my-8 mx-auto rounded-sm max-w-[65rem] ">
                <div className="text-sm breadcrumbs max-w-md">
                    <ul>
                        <li>Klient</li>
                        <li>Dodaj awizację</li>
                    </ul>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="inline-grid max-w-md">
                        <div className="block max-w-md">
                            <h1 className="text-2xl font-bold md-3 max-w-md">Nowa awizacja</h1>
                            <br/>
                            <span className="text-sm font-bold">TYP ZAMÓWIENIA</span>
                            <select id="type_selection"
                                    className={"select select-sm w-full border-gray-400 rounded-none font-normal"}
                                    disabled={success}
                                    onChange={() => {
                                        dispatch({
                                            type: "setOrderType",
                                            payload: document.getElementById("type_selection").value
                                        })
                                    }}>
                                <option defaultValue value={0}>Samochodowe</option>
                            </select>
                        </div>

                        <div className="block max-w-md min-w-md">
                            <div className="mt-2">
                                <span className="text-sm font-bold">KLIENT</span>
                                <br/>
                                <input type="text" placeholder={getJwtTokenCompany()} disabled
                                       className="input input-sm w-full rounded-none"/>
                            </div>
                        </div>

                        <div className="block max-w-md min-w-md text-sm font-bold mt-1">
                            <div className="mt-2">
                                DATA ZAMÓWIENIA
                                <input type="date"
                                       disabled={success}
                                       className={"input input-bordered input-sm w-full border-gray-400 rounded-none font-normal" + (errorDict['date'] ? ' border-red-500' : '')}
                                       onChange={(e) => {
                                           if (e.target.value !== '' && e.target.value !== undefined && e.target.value >= new Date().toISOString().split('T')[0]) {
                                               setErrorDict({...errorDict, date: false});
                                               dispatch({type: "setOrderDate", payload: e.target.value})
                                           } else {
                                               setErrorDict({...errorDict, date: true});
                                               dispatch({type: "setOrderDate", payload: ''})
                                           }
                                       }}/>
                            </div>
                        </div>
                        <div className="form-control max-w-md">
                            <div className="mt-2">
                                <span className="text-sm font-bold">WAŻNE PRZEZ</span>
                                <select id="pickup_choice_list"
                                        className="select select-sm w-full rounded-none border-gray-400 font-normal"
                                        disabled={success}
                                        onChange={() =>
                                            dispatch({
                                                type: "setDaysToPick",
                                                payload: Number(document.getElementById("pickup_choice_list").value)
                                            })
                                        }>
                                    <option defaultValue value={0}>Do końca dnia</option>
                                    <option value={1}>1 dzień</option>
                                    <option value={2}>2 dni</option>
                                    <option value={3}>3 dni</option>
                                    <option value={4}>4 dni</option>
                                    <option value={5}>5 dni</option>
                                    <option value={6}>6 dni</option>
                                    <option value={7}>7 dni</option>
                                    <option value={8}>8 dni</option>
                                    <option value={9}>9 dni</option>
                                    <option value={10}>10 dni</option>
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="form-control max-w-md">
                            <div className=" mt-2 ">
                                <span className="text-sm font-bold">TERMIN PŁATNOŚCI</span>
                                <select id="payment_choice_list"
                                        className="select select-sm w-full border-gray-400 rounded-none font-normal"
                                        disabled={success}
                                        onChange={() =>
                                            dispatch({
                                                type: "setPaymentDays",
                                                payload: Number(document.getElementById("payment_choice_list").value)
                                            })
                                        }>
                                    <option defaultValue value={0}>Przedpłata</option>
                                    <option value={7}>7 dni</option>
                                    <option value={14}>14 dni</option>
                                    <option value={21}>21 dni</option>
                                    <option value={30}>30 dni</option>
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="form-control max-w-md">
                            <div className=" mt-2 ">
                                <span className="text-sm font-bold">PRODUKT</span>
                                <select id="product_choice_list"
                                        className={"select select-sm w-full border-gray-400 rounded-none font-normal" + (errorDict['product'] ? ' border-red-500' : '')}
                                        disabled={success}
                                        onChange={() => {
                                            dispatch({
                                                type: "setProductId",
                                                payload: Number(document.getElementById("product_choice_list").value)
                                            })
                                            setErrorDict({...errorDict, product: false});
                                        }
                                        }>
                                    <option defaultValue value={-1}>Wybierz produkt</option>
                                    {
                                        productList.map((product) => (
                                            <option key={"product_" + product.id}
                                                    value={Number(product.id)}>{product.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div className={"form-control max-w-md"}>
                            <div className={" mt-2"}>
                                <span className={"text-sm font-bold"}>TERMINAL</span>
                                <select id="terminal_choice_list"
                                        className={"select select-sm w-full border-gray-400 rounded-none font-normal" + (errorDict['terminal'] ? ' border-red-500' : '')}
                                        disabled={success}
                                        onChange={() => {
                                            dispatch({
                                                type: "setTerminalId",
                                                payload: Number(document.getElementById("terminal_choice_list").value)
                                            })
                                            setErrorDict({...errorDict, terminal: false});
                                        }}>
                                    <option defaultValue value={-1}>Wybierz Terminal</option>
                                    {
                                        terminalList.map((terminal) => (
                                            <option key={"terminal_" + terminal.id}
                                                    value={Number(terminal.id)}>{terminal.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="form-control max-w-md">
                            <label className=" mt-2 ">
                                <span className="text-sm font-bold">ILOŚĆ SAMOCHODÓW</span>
                                <input type="number" placeholder="Ilość samochodów"
                                       value={state.QuantityCars !== undefined ? state.QuantityCars : ""}
                                       className={"input input-bordered input-sm w-full border-gray-400 rounded-none font-normal" + (errorDict['quantityCars'] ? ' border-red-500' : '')}
                                       disabled={success}
                                       onChange={(e) => {
                                           {
                                               if (e.target.value !== '' && e.target.value !== undefined && e.target.value > 0) {
                                                   setErrorDict({...errorDict, quantityCars: false});
                                                   dispatch({
                                                       type: "setQuantityCars",
                                                       payload: e.target.value
                                                   })
                                               } else {
                                                   setErrorDict({...errorDict, quantityCars: true});
                                                   dispatch({type: "setQuantityCars", payload: undefined})
                                               }
                                           }
                                       }}/>
                            </label>
                        </div>

                        <div className="form-control max-w-md">
                            <label className=" mt-2 ">
                                <span className="text-sm font-bold">ILOŚĆ W TONACH</span>
                                <input type="number" placeholder="Ilość w tonach"
                                       value={state.QuantityTons || ""}
                                       className={"input input-bordered input-sm w-full border-gray-400 rounded-none font-normal" + (errorDict['quantity'] ? ' border-red-500' : '')}
                                       disabled={success}
                                       onChange={(e) => {
                                           if (e.target.value !== '' && e.target.value !== undefined && e.target.value > 0) {
                                               setErrorDict({...errorDict, quantity: false});
                                               dispatch({type: "setQuantityTons", payload: e.target.value})
                                           } else {
                                               setErrorDict({...errorDict, quantity: true});
                                               dispatch({type: "setQuantityTons", payload: 0})
                                           }
                                       }}/>
                            </label>
                        </div>

                        <div className="form-control max-w-md">
                            <label className=" mt-2 ">
                                <span className="text-sm font-bold">KOMENTARZ</span>
                                <textarea placeholder="Komentarz"
                                          className="textarea textarea-bordered border-gray-400 px-3 rounded-none w-full"
                                          disabled={success}
                                          onChange={(e) => dispatch({
                                              type: "setComment",
                                              payload: e.target.value
                                          })}/>
                            </label>
                        </div>
                    </div>
                    <div className="max-w-md">
                        <div className="p-4 pt-0 bg-white">
                            <div className=''>
                                <h1 className="text-2xl font-bold mb-12">Podsumowanie</h1>
                                <div
                                    className="flex text-md mb-2 pl-1 bg-gray-200 place-content-between align-middle">Wartość
                                    zamówienia
                                    (brutto z VAT)
                                    <nobr>
                                        <h1 className="flex mb-1 text-lg font-bold text-right">{new Intl.NumberFormat("de-DE", {
                                            style: 'currency',
                                            currency: 'PLN'
                                        }).format(priceNetto * 1.23 * state.QuantityTons || 0)}</h1>
                                    </nobr>
                                </div>
                                <br/>
                                <div
                                    className="flex text-md mb-2 pl-1 bg-gray-200 place-content-between align-middle">Wartość
                                    zamówienia
                                    (netto)
                                    <nobr>
                                        <h1 className="flex mb-1 text-lg font-bold text-right">{new Intl.NumberFormat("de-DE", {
                                            style: 'currency',
                                            currency: 'PLN'
                                        }).format(priceNetto * state.QuantityTons || 0)}</h1>
                                    </nobr>
                                </div>
                                <br/>
                                <div
                                    className="flex text-md pl-1 bg-gray-200 place-content-between align-middle">Cena
                                    za tonę (netto)
                                    <nobr>
                                        <h1 className="flex mb-1 text-lg font-bold text-right">{new Intl.NumberFormat("de-DE", {
                                            style: 'currency',
                                            currency: 'PLN'
                                        }).format(priceNetto)}</h1>
                                    </nobr>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer w-[17rem]">
                        <span className="text-md font-bold">TRANSPORT SBK</span>
                        <input type="checkbox"
                               className="toggle ml-8 border-gray-400 checked:bg-yellow-600 checked:border-gray-400"
                               disabled={success}
                               checked={state.TransportSbk} onChange={() => {
                        }}
                               onClick={() => dispatch({type: "setTransportSbk", payload: !state.TransportSbk})}/>
                    </label>
                </div>

                {state.QuantityCars > 0 && !state.TransportSbk ?
                    <div className="">
                        <span className="font-bold ml-1">KIEROWCY</span> <br/>
                        <table className="block bg-gray-200 overflow-x-auto mt-2 relative w-auto text-left pb-3">
                            <thead>
                            <tr>
                                <th className="text-left pl-7">Imię *</th>
                                <th className="text-left pl-4">Nazwisko *</th>
                                <th className="text-left pl-4">Samochód *</th>
                                <th className="text-left pl-4">Telefon</th>
                                <th className="text-left pl-4">Dokument</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.Drivers.map((driver, index) => (
                                <tr key={index}>
                                    <td>
                                        <input type="text" placeholder="Imię"
                                               className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none"
                                               disabled={success}
                                               value={state.Drivers[index].FirstName}
                                               onChange={(e) => dispatch({
                                                   type: "updateDriver",
                                                   payload: {index: index, firstName: e.target.value}
                                               })}/>
                                    </td>
                                    <td>
                                        <input type="text" placeholder="Nazwisko"
                                               className="input input-bordered input-sm bordered-base-100 rounded-none"
                                               disabled={success}
                                               value={state.Drivers[index].LastName}
                                               onChange={(e) => dispatch({
                                                   type: "updateDriver",
                                                   payload: {index: index, lastName: e.target.value}
                                               })}/>
                                    </td>
                                    <td>
                                        <input type="text" placeholder="Nr rejestracyjny"
                                               className="input input-bordered input-sm bordered-base-100 rounded-none"
                                               disabled={success}
                                               value={state.Drivers[index].CarPlate}
                                               onChange={(e) => dispatch({
                                                   type: "updateDriver",
                                                   payload: {index: index, car: e.target.value}
                                               })}/>
                                    </td>
                                    <td>
                                        <input type="text" placeholder="Telefon-cyfry bez spacji"
                                               className="input input-bordered input-sm bordered-base-100 rounded-none"
                                               disabled={success}
                                               value={state.Drivers[index].PhoneNumber}
                                               onChange={(e) => dispatch({
                                                   type: "updateDriver",
                                                   payload: {index: index, phone: e.target.value}
                                               })}/>
                                    </td>
                                    <td>
                                        <input type="text" placeholder="Dokument tożsamości"
                                               className="input input-bordered input-sm bordered-base-100 rounded-none mr-3"
                                               disabled={success}
                                               value={state.Drivers[index].DocumentNumber}
                                               onChange={(e) => dispatch({
                                                   type: "updateDriver",
                                                   payload: {index: index, document: e.target.value}
                                               })}/>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div> : null}
                {/*<label className="text-sm font-bold mt-1">ZAŁĄCZNIK</label> <br/>*/}
                {/*<input*/}
                {/*    type="file"*/}
                {/*    className="file-input file-input-bordered file-input-sm w-full max-w-xs rounded-none"*/}
                {/*    onChange={saveFile}*/}
                {/*    disabled={success}*/}
                {/*/> <br/>*/}
                <nobr>
                    <button
                        className="btn btn-md mt-2 right-3 bg-yellow-600 border-none rounded-sm disabled:bg-yellow-600 disabled:text-gray-200"
                        onClick={handleSubmit} disabled={loading || success}>
                        {!loading && !success ? "Utwórz zlecenie" : !success ? "Ładowanie..." : "Zakończono"}
                    </button>
                    {
                        success ?
                            <a href={"/"}>
                                <button
                                    className="btn btn-md mt-2 right-3 bg-yellow-600 border-none rounded-sm ml-4">
                                    Powrót
                                </button>
                            </a>
                            : null
                    }
                </nobr>
                {error !== "" ?
                    <div
                        className="alert alert-error shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                 fill="none"
                                 viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            <span>{error}</span>
                        </div>
                        <button className="btn btn-ghost btn-sm" onClick={() => setError("")}>Zamknij</button>
                    </div> : <div/>
                }
                {success ?
                    <div
                        className="alert alert-success shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                 fill="none" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            <span>Zlecenie utworzone pomyślnie</span>
                        </div>
                    </div> : null
                }
            </div>
        </div>
    );
}