import { useEffect, useState } from "react";
import { useNavigation, useSearchParams } from "react-router-dom";
import { getCustomerList } from "../../app/requests/user";



function createInitialSearchCriteria(searchParams) {
    return {
        UserName: searchParams.get("UserName") || "",
        CompanyName: searchParams.get("CompanyName"),
        Email: searchParams.get("Email") || '',
        Country: searchParams.get("Country") || '',
        State: searchParams.get("State") || '',
        PhoneNumber: searchParams.get("PhoneNumber") || '',
        TaxNumber: searchParams.get("TaxNumber") || '',
        Address: searchParams.get("Address") || '',
        BuildingNumber: searchParams.get("BuildingNumber") || '',
        City: searchParams.get("City") || '',
        PostCode: searchParams.get("PostCode") || '',
        DateFrom: searchParams.get("DateFrom") || '',
        DateTo: searchParams.get("DateTo") || '',
        Role: "Klient"
    }
}

export default function UsersListTrader() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchCriteria, setSearchCriteria] = useState(createInitialSearchCriteria(searchParams));

    const [customerList, setCustomerList] = useState([]);
    const [filtredList, setFiltredList] = useState([]);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(25);

    const [error, setError] = useState(null);

    const [login, setLogin] = useState('')
    const [companyData, setCompanyData] = useState('')
    const [detail, setDetail] = useState('')

    function filterTable(login, companyData, details) {
        let result = customerList;
        if (login !== '') {
            result = result.filter(x =>
                x.userName.includes(login) ||
                x.companyShortName.includes(login)
            );
        }
        if (companyData !== '') {
            result = result.filter(x =>
                (String(x.taxNumber)).includes(companyData) ||
                x.companyName.includes(companyData) ||
                x.email.includes(companyData) ||
                (String(x.phoneNumber)).includes(companyData)
            );
        }
        if (details !== '') {
            result = result.filter(x =>
                x.createdAt.includes(details)
            );
        }
        return result;
    }


    function fetchData() {


        getCustomerList().then(r => setCustomerList(r)).catch("problem");
        if (filtredList.length === 0 && login === '' && companyData === '' && detail === '') {
            getCustomerList().then(r => setFiltredList(r)).catch("problem");
        }
    }


    useEffect(fetchData, []);

    console.log(filtredList);
    console.log(customerList);
    return (
        <div className="p-8">
            <h1 className="text-2xl font-bold md-3 max-w-md mb-5">Lista klientów</h1>
            <div className="mt-5 overflow-x-auto">
                <table className="table table-compact table-zebra w-full rounded-none static">
                    <thead className="rounded-none">
                        <tr key="head" className="rounded-none">
                            <th className="rounded-none w-1/12" />
                            <th className="rounded-none w-1/3">Użytkownik</th>
                            <th className="rounded-none w-1/3">Dane firmy</th>
                            <th className="rounded-none w-1/6">Szczegóły konta</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td className="w-36">
                                <input type="text" className="border border-gray-400 bg-gray-200 rounded-none p-1 max-w-md"
                                    onKeyDown={event => {
                                        if (event.key === 'Enter') fetchData();
                                    }}
                                    onChange={e => {
                                        setLogin(e.target.value)
                                        setFiltredList(filterTable(e.target.value, companyData, detail));

                                    }
                                    } />
                            </td>
                            <td>
                                <input type="text" className="border border-gray-400 bg-gray-200  rounded-none p-1 max-w-md"
                                    onKeyDown={event => {
                                        if (event.key === 'Enter') fetchData();
                                    }}
                                    onChange={e => {
                                        setCompanyData(e.target.value);
                                        setFiltredList(filterTable(login, e.target.value, detail));
                                    }
                                    } />
                            </td>
                            <td>
                                <input type="text" className="border border-gray-400 bg-gray-200 rounded-none p-1 max-w-md"
                                    onKeyDown={event => {
                                        if (event.key === 'Enter') fetchData();
                                    }}
                                    onChange={e => {

                                        setDetail(e.target.value);
                                        setFiltredList(filterTable(login, companyData, e.target.value));

                                    }} />
                            </td>
                        </tr>
                        {filtredList && filtredList.map(c => <tr key={c.id}>
                            <td>{c.id}</td>
                            <td>{c.userName}<br />{c.companyShortName}</td>
                            <td>
                                <table className="table table-compact static">
                                    <tbody>
                                        <tr>
                                            <td>Nazwa firmy</td>
                                            <td>{c.companyName}</td>
                                        </tr>
                                        <tr>
                                            <td>NIP</td>
                                            <td>{c.taxNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>adres email</td>
                                            <td>{c.email}</td>
                                        </tr>
                                        <tr>
                                            <td>numer telefonu</td>
                                            <td>{c.phoneNumber}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>
                                <table className="table table-compact static">
                                    <tbody>
                                        <tr>
                                            <td>Data rejestracji</td>
                                            <td>{c.createdAt}</td>
                                        </tr>
                                        <tr>
                                            <td>Potwierdzony</td>
                                            <td>{String(c.isActive)}</td>
                                        </tr>
                                        <tr>
                                            <td>Zaakceptowany</td>
                                            <td>{String(c.isAccountAccepted)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>)}

                    </tbody>
                </table>
            </div>
        </div>
    )
}