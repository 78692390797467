import React from 'react';

export const DropdownTerminal = ({...args}) => {
    return (
        <div className="dropdown dropdown-bottom z-10">
            <label tabIndex={0} className="btn m-1 mt-0 bg-yellow-600 border-0 rounded-sm z-10">Terminal</label>
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-auto whitespace-nowrap z-10">
                <li><a href={'orders-terminal'} className={"btn-ghost hover:bg-yellow-600 z-10"}>Lista zleceń</a></li>
                <li><a href={'orders-terminal?DateFrom=' + new Date().toISOString().split('T')[0] + '&DateTo=' + new Date().toISOString().split('T')[0]} className={"btn-ghost hover:bg-yellow-600 z-10"}>Zlecenia dzisiaj</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600 z-10"}>Raporty</a></li>
            </ul>
        </div>
    );
}

export const DropdownTerminalMobile = ({...args}) => {
    return (
            <ul className="menu">
                <li><a href={'orders-terminal'} className="btn btn-ghost">Lista zleceń</a></li>
                <li><a href={'orders-terminal?DateFrom=' + new Date().toISOString().split('T')[0] + '&DateTo=' + new Date().toISOString().split('T')[0]} className="btn btn-ghost">Zlecenia dzisiaj</a></li>
                <li><a className="btn btn-ghost">Raporty</a></li>
            </ul>
    );
}

export default DropdownTerminal;
