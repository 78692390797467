
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { updateUser, getUserData } from "../../app/requests/userUpdate";
import {
    isJwtTokenExpired
} from "../../app/token_service";


export const UserPreviewScreen = () => {
    const [UserName, setUserName] = useState('');

    const [Email, setEmail] = useState('');
    const [CompanyName, setCompanyName] = useState('');
    const [CompanyShortName, setCompanyShortName] = useState('');
    const [Country, setCountry] = useState('');
    const [State, setState] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [TaxNumber, setTaxNumber] = useState('');
    const [Address, setAddress] = useState('');
    const [BuildingNumber, setBuildingNumber] = useState('');
    const [City, setCity] = useState('');
    const [PostCode, setPostCode] = useState('');
    const [error, setError] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();


    //const api = 
   


    useEffect(() => {
        const userData = async () => {
            await getUserData(Number(searchParams.get('id')))
                .then(response => {
                    console.log(response)
                    setUserName(response.userName);
                    setAddress(response.address);
                    setEmail(response.email);
                    setCompanyName(response.companyName);
                    setCompanyShortName(response.companyShortName);
                    setCountry(response.country);
                    setState(response.state);
                    setPhoneNumber(response.phoneNumber);
                    setTaxNumber(response.taxNumber);
                    setBuildingNumber(response.buildingNumber);
                    setCity(response.city);
                    setPostCode(response.postCode);
                    setError('');

                })

        }
        userData().catch(error => {
            setError("Wystąpił błąd podczas danych użytkownika");
        });
    }, []);

    if (!isJwtTokenExpired()) {

        return (
            <div className="p-8 pt-0 my-8 mx-auto rounded-sm max-w-[96rem]">
                <div className="userUpdate-screen__form">
                    <div className="userUpdate-screen__form__header">
                        <center><h1 className="text-2xl font-bold md-3 max-w-md">Edycja profilu{' ' + UserName}</h1></center>
                    </div>
                    {error && <p className="error">{error}</p>}
                    <div className="userUpdate-screen__form__body">
                        <form>
                            <div className="overflow-x-auto ml-12">
                                <table className="table table-compact w-full static">
                                    <tbody>
                                        <tr className={"z-0"}>
                                            <td ><label className="text-sm font-bold mt-1 text-lg">Nazwa użytkownika</label></td>
                                            <td ><input
                                                type="text"
                                                placeholder="Nazwa użytkownika"
                                                className="input input-bordered input-sm bordered-base-100 ml-3  bg-base-100 rounded-none w-full max-w-md"
                                                value={UserName}
                                                disabled

                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Adres E-mail</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="adres email"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={Email}
                                                disabled
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Pełna nazwa firmy</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Nazwa firmy"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={CompanyName}
                                                disabled
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Skrócona nazwa firma</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Skrócona nazwa firma"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={CompanyShortName}
                                                disabled
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Państwo</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Państwo"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={Country}
                                                disabled
                                                /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Region</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Region"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={State}
                                                disabled/>
                                            </td>
                                             </tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Numer telefonu</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Numer telefonu"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={PhoneNumber}
                                                    disabled
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Numer NIP(10 cyfr bez myślników)</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="NIP"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={TaxNumber}
                                                    disabled
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Adres</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Adres"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={Address}
                                                disabled/>
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Numer budynku</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Numer budynku"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={BuildingNumber}
                                                    disabled/>
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Miasto</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Miasto"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={City}
                                                    disabled
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Kod pocztowy</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Kod pocztowy"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={PostCode}
                                                    disabled
                                            /></td></tr>
                                    </tbody>
                                </table>
                            </div>
                            {error !== "" ?
                                <div className="alert alert-error shadow-lg">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <span>{error}</span>
                                    </div>
                                </div> : <div />
                            }

                        </form>

                    </div>
                </div>
            </div>
        );
    }
    else {
        return (<div />)
    }
};