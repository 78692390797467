import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { LoginScreen } from "./screens/LoginScreen/LoginScreen";
import { RegisterScreen } from "./screens/RegistrationScreen/RegistrationScreen";
import {StatuteScreen} from "./screens/StatuteScreen/StatuteScreen";
import {PasswordResetScreen} from "./screens/PasswordResetScreen/PasswordResetScreen";
import {ForgotPasswordScreen} from "./screens/ForgotPasswordScreen/ForgotPasswordScreen";
import { UserUpdateScreen } from "./screens/UpdateScreen/UserUpdateScreen";
import { UserPreviewScreen } from "./screens/UserListScreen/UserPreviewScreen";
import CreateOrderScreen from "./screens/CreateOrderScreen/CreateOrderScreen";
import NotFoundScreen from "./screens/404Screen/NotFoundScreen";
import OrderListTrader from "./screens/OrderListScreen/OrderListScreenTrader";
import OrderListCustomer from "./screens/OrderListScreen/OrderListScreenCustomer";
import OrderListTerminal from "./screens/OrderListScreen/OrderListScreenTerminal";
import { VerifyUserScreen } from "./screens/VerifyScreen/VerifyUserScreen";
import OrderDetailsScreen from "./screens/OrderDetailsScreen/OrderDetailsScreen";
import NewUsersListTrader from "./screens/UserListScreen/NewUserListScreen"
import UsersListTrader from "./screens/UserListScreen/UsersListTrader"
const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  },
  {
    path: '/login',
    element: <LoginScreen />
    },
   {
     path: '/registration',
     element: <RegisterScreen />
   },
  {
    path: '/statute',
    element: <StatuteScreen />
  },
  {
    path: '/reset-password/:token',
    element: <PasswordResetScreen />
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordScreen />
  },
  {
    path: '/myProfile',
    element: <UserUpdateScreen />
  },
  {
    path: '/create-order',
    element: <CreateOrderScreen />
  },
  {
    path: '/orders-trader',
    element: <OrderListTrader/>
  },
  {
    path: '/orders-client',
    element: <OrderListCustomer/>
  },
  {
    path: '/orders-terminal',
    element: <OrderListTerminal/>
  },
  {
    path: '*',
    element: <NotFoundScreen/>
  },
  {
    path: '/userVerify/:token',
    element: <VerifyUserScreen />
  },
  {
    path: '/order-details/:id',
    element: <OrderDetailsScreen />
  },
  {
      path: '/new-users',
      element: <NewUsersListTrader />
    },
  {
      path: '/user',
      element: <UserPreviewScreen />
    },
    {
        path: '/users-list',
        element: <UsersListTrader />
    }



];

export default AppRoutes;
