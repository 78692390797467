import axios from "axios";
import {getJwtToken} from "../token_service";
import {API_URL} from "../constants";
import {setSelectionRange} from "@testing-library/user-event/dist/utils";

export function createOrder(order, file) {
    let err = null;
    let resp = null;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        let data = JSON.stringify({...order, files: [{FileName: file.name, FileContent: btoa(reader.result)}]});
        // let data = JSON.stringify(order)

        let config = {
            method: 'post',
            url: API_URL + '/api/order',
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getJwtToken()
            },
            data: data
        };


        return new Promise((resolve, reject) => {
            axios(config)
                .then(response => {
                    resp = response;
                    resolve(response);
                })
                .catch(error => {
                    err = error;
                    reject(error);
                })
        })
    };

    reader.onerror = function (error) {
        err = error;
        throw new Error(error);
    }
    return new Promise(async (resolve, reject) => {
            for (let i = 0; i < 100000; i++) {
                if (err) {
                    reject(err);
                }
                if (resp) {
                    resolve(resp);
                }
                await sleep(100);
            }
        }
    )
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function updateOrder(order) {
    console.log(order)
    let data = JSON.stringify(order);
    let config = {
        method: 'put',
        url: API_URL + '/api/order',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        },
        data: data
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                console.log(response);
                resolve(response);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

export function getOrderPrice(order) {
    let data = JSON.stringify(order);
    let config = {
        method: 'post',
        url: API_URL + '/api/order/pricing',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        },
        data: data
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function getOrdersBySearchCriteria(searchCriteria) {
    if (searchCriteria.WeightFrom === "") {
        searchCriteria.WeightFrom = 0;
    }
    if (searchCriteria.WeightTo === "") {
        searchCriteria.WeightTo = 100000;
    }
    if (searchCriteria.DateFrom === "") {
        searchCriteria.DateFrom = "2020-01-01";
    }
    if (searchCriteria.DateTo === "") {
        searchCriteria.DateTo = "2100-01-01";
    }

    let data = JSON.stringify(searchCriteria);
    let config = {
        method: 'post',
        url: API_URL + '/api/order/search',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        },
        data: data
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function getOrderById(orderId) {
    let config = {
        method: 'get',
        url: API_URL + '/api/order/' + orderId,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        }
    };

    return new Promise((resolve, reject) => {
            axios(config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                })
        }
    );
}

export function acceptOrder(orderId) {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/accept/' + orderId,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        }
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function rejectOrder(orderId) {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/reject/' + orderId,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        }
    };

    return new Promise((resolve, reject) => {
            axios(config)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        }
    );
}

export function cancelOrder(orderId) {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/cancel/' + orderId,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        }
    };

    return new Promise((resolve, reject) => {
            axios(config)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        }
    );
}

export function renewOrder(orderId) {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/renew/' + orderId,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        }
    };

    return new Promise((resolve, reject) => {
            axios(config)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        }
    );
}

export function completeOrder(orderId) {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/complete/' + orderId,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        }
    };

    return new Promise((resolve, reject) => {
            axios(config)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        }
    );
}

export function completeOrderDriver(orderId, driverId, tons) {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/complete/' + orderId + '/' + driverId + '/' + tons,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        }
    };

    return new Promise((resolve, reject) => {
            axios(config)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        }
    );
}
