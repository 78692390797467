import axios from "axios";
import {API_URL} from "../constants";
import {getJwtToken} from "../token_service";


export async function getTerminalList() {
    let config = {
        method: 'get',
        url: API_URL + '/api/terminal',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getJwtToken()
        }
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}
